import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const SchedulePage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Agenda</title>
      </Helmet>
      <p>Olá</p>
    </Layout>
  )
}

export default SchedulePage
